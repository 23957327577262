


















import {Component, Prop} from 'vue-property-decorator'
import { Tree } from 'element-ui'

import Documents from '@/mixins/Documents'
import { documentsNamespace } from '@store/documents'
import { IDocument, ITreeCheckboxData } from '@store/documents/Interface'
import { mixins } from 'vue-class-component'

@Component({
  components: {
    'v-unit': () => import('./Unit.vue'),
  },
})
export default class VTree extends mixins(Documents) {
  @documentsNamespace.State('documentsByVolume')
  private documentsByVolume!: IDocument[]

  @Prop(Boolean) private isOwner

  private checkedDocuments: ITreeCheckboxData  = {nodes: [], labels: [], count: 0}

  private get props () {
    const props = {
      label: data => data.type === 'tom' ? 'name' : 'filename',
      children: 'content',
      isLeaf: data => data.type !== 'tom',
    }
    return props
  }

  private handleCheckChange () {
    this.checkedDocuments.nodes = this.$refs ? (this.$refs.tree as Tree).getCheckedNodes() : []
    this.checkedDocuments.labels = this.$refs ? (this.$refs.tree as Tree).getCheckedKeys() : []
    this.checkedDocuments.count = this.checkedDocuments.labels.length
    this.$emit('onCheck', this.checkedDocuments)
  }

  private loadNode(node, resolve) {
    if (node.data.content) {
      resolve(node.data.content)
    }
  }


}
